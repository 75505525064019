document.addEventListener("turbolinks:load", () => {

  $(function () {
    var focusedElement;
    $(document).on('focus', 'input', function () {
        if (focusedElement == this) return; //already focused, return so user can now place cursor at specific point in input.
        focusedElement = this;
        setTimeout(function () { focusedElement.select(); }, 100); //select all text in any field on focus for easy re-entry. Delay sightly to allow focus to "stick" before selecting.
    });
  });

  $(document).on('select2:open', () => {
    document.querySelector('.select2-search__field').focus();
  });
});
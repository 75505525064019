import IMask from "imask";

document.addEventListener("turbolinks:load", () => {
  const phones = document.querySelectorAll("[data-phone]");
  [].forEach.call(phones, (phone) => {
    const maskOptions = {
      mask: "+{7} (000) 000-00-00",
      lazy: false,
    };
    const mask = new IMask(phone, maskOptions);
  });
});

document.addEventListener('turbolinks:load', function(){
    var commentEdit = document.querySelectorAll(".comment-box")

    commentEdit.forEach(function(comment){
        comment.addEventListener('click', function(e){
            var {target} = e
            
            if(!target.classList.contains('comment_edit')) return;
            
            e.preventDefault()

            var commentId = target.dataset.commentId
            
            var commentEditForm = document.querySelectorAll(`#edit-comment-form-${commentId}`)

            commentEditForm.forEach(function(form){
                form.classList.toggle('hidden')
            })

            // commentEditForm.classList.remove('hidden')  
            target.style.display = 'none'
        })
    })

})

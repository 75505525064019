document.addEventListener("turbolinks:load", () => {
  const additionalServiceSelect = document.getElementById('additional_service_id');
  const serviceCodeInput = document.getElementById('additional_service_code');
  const serviceAmountInput = document.getElementById('additional_service_amount');
  const serviceCodeHidden = document.getElementById('additional_service_code_hidden');


  additionalServiceSelect.addEventListener('change', function() {
    const serviceId = this.value;

    if (!serviceId) {
      clearForm();
      return;
    }

    fetch(`/office/additional_services/${serviceId}.json`)
      .then(response => {
        if (!response.ok && response.status === 404) {
          throw new Error('404 Not Found');
        }
        return response.json();
      })
      .then(data => {
        serviceCodeInput.value = data.code;
        serviceAmountInput.value = data.amount;
        serviceCodeHidden.value = data.code;
      })
      .catch(error => {
        console.error('Error fetching data:', error);
        clearForm();
      });
  });

  function clearForm() {
    serviceCodeInput.value = '';
    serviceAmountInput.value = '';
  }
});

function attachEventHandlers() {
  let expandableTriggers = document.querySelectorAll('.expandable-trigger');

  expandableTriggers.forEach((trigger) => {
    trigger.addEventListener('click', function() {
      let targetId = this.getAttribute('data-target');
      let targetContent = document.querySelector(targetId);
      let icon = this.querySelector('.fa-chevron-down') || this.querySelector('.fa-chevron-up');

      if (targetContent.classList.contains('is-hidden')) {
        targetContent.classList.remove('is-hidden');
        targetContent.classList.add('is-active');
        if (icon) icon.classList.add('rotate-icon');
      } else {
        targetContent.classList.add('is-hidden');
        targetContent.classList.remove('is-active');
        if (icon) icon.classList.remove('rotate-icon');
      }
    });
  });
}

document.addEventListener("turbolinks:load", attachEventHandlers);

$(document).on('ajax:success', '#filter-form', function() {
  attachEventHandlers();
});

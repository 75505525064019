import { Controller } from "stimulus";
import Rails from "@rails/ujs";
import { debounce } from "lodash";
import axios from "axios";

export default class extends Controller {
  initialize() {
    this.updateParam = debounce(this.updateParam.bind(this), 100);
  }

  updateParam = async (event) => {
    const url = `/office/parcels/${this.parcelId}.json`;
    const data = this.pickParam(event);

    const preloader = event.target.closest(".control");
    preloader.classList.add("is-loading");

    try {
      const response = await axios.patch(url, data, {
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-Token": Rails.csrfToken(),
        },
      });

      const volumetricInputs = document.querySelectorAll(`tr[data-parcel-id="${this.parcelId}"] .control input[name="parcel_volumetric_weight"]`);
      const destinationWeightInputs = document.querySelectorAll('input[name="destination_total_weight"]');

      for (const input of volumetricInputs) {
        input.value = response.data.volumetric_weight;
      }

      for (const input of destinationWeightInputs) {
        input.value = response.data.destination_weight;
      }

      preloader.classList.remove("is-loading");
    } catch (error) {
      console.log(error);
    }
  };

  pickParam = (event) => {
    const { name, value } = event.target;

    switch (name) {
      case "parcel_height":
        return { parcel: { h: value }};
      case "parcel_width":
        return { parcel: { w: value }};
      case "parcel_length":
        return { parcel: { l: value }};
      case "parcel_weight":
        return { parcel: { weight: this.normalizeWeight(value) }};
      default:
        return {};
    }
  };

  normalizeWeight = (weightString) => {
    if (weightString === "") {
      return 0;
    }

    return parseFloat(weightString.replace(",", "."));
  };

  get parcelId() {
    return this.data.get("id");
  }
}

import 'select2';
import 'select2/dist/css/select2.css';

function formatState (state, subtitleKey) {
  if (!state.id) {
    return state.text;
  }

  const $state = $(
    `<span class="select2-title">${state.title}</span>
     <span class="select2-subtitle">${state[subtitleKey]}</span>
    `
  );
  return $state;
};

function formatSelection (state) {
  if (!state.id) {
    return state.text;
  }

  var $state = $(
    `<span>${state.title || state.text}</span>`
  );

  // Use .text() instead of HTML string concatenation to avoid script injection issues
  $state.find("span").text(state.text);

  return $state;
};

$.fn.select2.amd.define('select2/i18n/ru',[],function () {
  // Russian
  return {
      errorLoading: function () {
          return 'Результат не может быть загружен.';
      },
      inputTooLong: function (args) {
          var overChars = args.input.length - args.maximum;
          var message = 'Пожалуйста, удалите ' + overChars + ' символ';
          if (overChars >= 2 && overChars <= 4) {
              message += 'а';
          } else if (overChars >= 5) {
              message += 'ов';
          }
          return message;
      },
      inputTooShort: function (args) {
          var remainingChars = args.minimum - args.input.length;

          var message = 'Пожалуйста, введите ' + remainingChars + ' или более символов';

          return message;
      },
      loadingMore: function () {
          return 'Загружаем ещё ресурсы…';
      },
      maximumSelected: function (args) {
          var message = 'Вы можете выбрать ' + args.maximum + ' элемент';

          if (args.maximum  >= 2 && args.maximum <= 4) {
              message += 'а';
          } else if (args.maximum >= 5) {
              message += 'ов';
          }

          return message;
      },
      noResults: function () {
        return 'Ничего не найдено';
      },
      searching: function () {
        return 'Поиск…';
      }
  };
});

document.addEventListener("turbolinks:load", () => {
  // In your Javascript (external .js resource or <script> tag)
  const elements = document.querySelectorAll("[data-fetch]")

  Array.from(elements).forEach( elem => {
    $(elem).select2({
      language: "ru",
      placeholder: "Выбрать из списка",
      allowClear: true,
      minimumInputLength: 2,
      templateResult: (state) => formatState(state, $(elem).data("subtitle")),
      templateSelection: formatSelection,
      data: [$(elem).data("selected")],
      ajax: {
        delay: 350,
        url: $(elem).data("fetch"),
        data: function (params) {
          const query = {
            q: {
              title_cont: params.term
            },
            format: "json"
          }
    
          // Query parameters will be ?search=[term]&type=public
          return query;
        },
        processResults: function (data) {
          // Transforms the top-level key of the response object from 'items' to 'results'
          return {
            results: data
          };
        }
      }
    });
  })
});

import flatpickr from "flatpickr";
import confirmDatePlugin from "flatpickr/dist/plugins/confirmDate/confirmDate.js";

function initializeFlatpickr() {
  const Russian = require("flatpickr/dist/l10n/ru.js").default.ru;

  flatpickr("[data-behavior='flatpickr-date-and-time']", {
    altInput: true,
    enableTime: true,
    altFormat: 'd.m.y H:i',
    dateFormat: "Y-m-d H:i",
    "locale": Russian,
    "plugins": [new confirmDatePlugin({
      confirmIcon: "<i class='fa fa-check'></i>", // your icon's html, if you wish to override
      confirmText: "OK ", // if you wish to override
      showAlways: false,
      theme: "light" // or "dark"
    })],
    onClose: function(selectedDates, dateStr, instance) {
      const event = new CustomEvent('date-selected', { detail: { date: dateStr } });
      instance.input.dispatchEvent(event);
    }
  })
}

document.addEventListener("turbolinks:load", initializeFlatpickr);

$(document).on('ajax:success', '#filter-form', initializeFlatpickr);

document.addEventListener('turbolinks:load', () => {
  // Get the dropdown menu and the hidden block elements
  const codeDescriptionDropdown = document.querySelector('select[name="delivery[code_description]"]');
  const hiddenBlock = document.getElementById('returning_codes_block');

  // Function to toggle the hidden block visibility
  function toggleHiddenBlockVisibility() {
    if (hiddenBlock && codeDescriptionDropdown.value === 'SUCCESS_PARTIAL') {
      hiddenBlock.classList.remove('is-hidden');
    } else if (hiddenBlock) {
      hiddenBlock.classList.add('is-hidden');
    }
  }

  // Call the function on page load to set the initial state
  toggleHiddenBlockVisibility();

  // Listen for changes in the dropdown menu if it exists
  if (codeDescriptionDropdown) {
    codeDescriptionDropdown.addEventListener('change', () => {
      toggleHiddenBlockVisibility();
    });
  }
});

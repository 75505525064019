import flatpickr from "flatpickr";

document.addEventListener("turbolinks:load", () => {
  const Russian = require("flatpickr/dist/l10n/ru.js").default.ru;

  flatpickr("[data-behavior='flatpickr-time']", {
    enableTime: true,
    noCalendar: true,
    dateFormat: "H:i",
    "locale": Russian,
    defaultDate: new Date(),
    disableMobile: true
  })
})

import { Controller } from "stimulus"
import Rails from "@rails/ujs"
import { debounce } from "lodash";
import axios from "axios";

export default class extends Controller {
  initialize() {
    this.updateQuantity = debounce(this.updateQuantity, 300).bind(this)
    this.updateWeight = debounce(this.updateWeight, 300).bind(this)
  }
  
  updateQuantity(event) {
    const url = `/office/destinations/${this.destinationId}.json`;
    const data = { destination: { quantity: event.target.value, kind: "quantitative" }};

    const preloader = event.target.closest(".control")
    preloader.classList.add("is-loading")

    axios.patch(url, data, {
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': Rails.csrfToken()
      }
    })
    .then(function (response) {
      preloader.classList.remove("is-loading")
    })
    .catch(function (error) {
      console.log(error);
    });
  }

  updateWeight(event) {
    const url = `/office/destinations/${this.destinationId}.json`;
    const data = { destination: { weight: this.normalizeWeight(event.target.value), kind: "quantitative" }};

    const preloader = event.target.closest(".control")
    preloader.classList.add("is-loading")

    axios.patch(url, data, {
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': Rails.csrfToken()
      }
    })
    .then(function (response) {
      preloader.classList.remove("is-loading")
    })
    .catch(function (error) {
      console.log(error);
    });
  }

  normalizeWeight(weightString) {
    if (weightString === "") { return 0 }
    return parseFloat(weightString.replace(",", "."))
  }

  get destinationId() {
    return this.data.get("id")
  }

  openTab(event) {
    event.preventDefault();

    const ul = event.target.closest("ul")
    const tabs = ul.querySelectorAll("li")
    const contents = document.querySelectorAll("[data-tab]")

    Array.from(contents).forEach(elem => {
      elem.classList.add("is-hidden")
    })

    Array.from(tabs).forEach(elem => {
      elem.classList.remove("is-active")
    })
    
    event.target.closest("li").classList.add("is-active")
    const contentToOpen = event.target.dataset.target
    
    document.querySelector(`[data-tab='${contentToOpen}']`).classList.remove("is-hidden")
  }
}
document.addEventListener("turbolinks:load", () => {
    let form_selector = document.getElementById("form_selector")
    let control_room_filter_section = document.querySelector(".control_room")
    let waybill_filter_section = document.querySelector(".waybill_filter")

    if(form_selector){
        form_selector.addEventListener("change", (e)=>{
            let selectedFormValue = form_selector.value
    
            if(selectedFormValue == 'waybill_filter'){
                control_room_filter_section.classList.add('hidden')
                waybill_filter_section.classList.remove('hidden')
            }else{
                control_room_filter_section.classList.remove('hidden')
                waybill_filter_section.classList.add('hidden')
            }
            
        })
    }
})
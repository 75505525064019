import { Controller } from "stimulus"

export default class extends Controller {
  openTab(event) {
    event.preventDefault();

    const ul = event.target.closest("ul")
    const tabs = ul.querySelectorAll("li")
    const contents = document.querySelectorAll("[data-tab]")

    Array.from(contents).forEach(elem => {
      elem.classList.add("is-hidden")
    })

    Array.from(tabs).forEach(elem => {
      elem.classList.remove("is-active")
    })
    
    event.target.closest("li").classList.add("is-active")
    const contentToOpen = event.target.dataset.target
    
    document.querySelector(`[data-tab='${contentToOpen}']`).classList.remove("is-hidden")
  }
}
import { Controller } from "stimulus"
import Rails from "@rails/ujs"
import { debounce } from "lodash";
import axios from "axios";

export default class extends Controller {
  initialize() {
    this.updateWeight = debounce(this.updateWeight, 300).bind(this)
  }

  updateWeight(event) {
    const url = `/office/legacy/waybills/${this.waybillId}`;
    const data = { waybill: { weight: this.normalizeWeight(event.target.value) } };

    const preloader = event.target.closest(".control")
    preloader.classList.add("is-loading")

    axios.patch(url, data, {
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': Rails.csrfToken()
      }
    })
    .then(function (response) {
      preloader.classList.remove("is-loading")
    })
    .catch(function (error) {
      console.log(error);
    });
  }

  normalizeWeight(weightString) {
    if (weightString === "") { return 0 }
    return parseFloat(weightString.replace(",", "."))
  }

  get waybillId() {
    return this.data.get("id")
  }
}
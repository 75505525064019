document.addEventListener("turbolinks:load", () => {
  $('#receivers').on('change', function() {
    const receiver_id = JSON.parse($(this).val());
    fetch(`/office/addresses/${receiver_id}.json`).then((response) => {
        return response.json();
      }).then((data) => {        
        fetch(`/office/regions/${data.region_id}.json`).then((response) => {
          return response.json();
        }).then((region_data) => {
          const region_title = region_data.title

          if (data.phone2) {
            var phones = [data.phone1, data.phone2].join(", ")
          } else {
            var phones = data.phone1
          }

          const option = new Option(region_title, data.region_id, true, true);
          $('#destination_destination_region_id').append(option).trigger('change');
          $('#destination_receiver').val(data.title);
          $('#destination_phone').val(phones);
          $('#destination_address').val(data.address);
          // manually trigger the `select2:select` event
          $('#destination_destination_region_id').trigger({
              type: 'select2:select',
              params: {
                  data: { id: data.region_id, title: region_title}
              }
          });
        })        
      });
  });

  
});
import "bulma-pageloader/dist/css/bulma-pageloader.min.css"

document.addEventListener('turbolinks:load', function() {
  var forms = document.querySelectorAll('form');
  var pageloader = document.querySelector('.pageloader');

  if (pageloader) {
    forms.forEach(function(form) {
      form.addEventListener('submit', function(){
        pageloader.classList.add('is-active');
      });
    });

    pageloader.classList.remove('is-active');
  }
});

import { Controller } from "stimulus";
import Rails from "@rails/ujs";
import axios from "axios";
import { debounce } from "lodash";

export default class extends Controller {
  static targets = ["input"];
  static values = { id: Number, field: String };

  connect() {
    this.updateField = debounce(this.updateField.bind(this), 300);
    this.inputTarget.addEventListener('date-selected', this.updateField);
    this.inputTarget.addEventListener('change', this.updateField);
  }

  disconnect() {
    this.inputTarget.removeEventListener('date-selected', this.updateField);
    this.inputTarget.removeEventListener('change', this.updateField);
  }

  updateField = async (event) => {
    const url = `/office/group_registries/${this.data.get("id")}/async_update.json`;
    let value = event.detail?.date || event.target.value;
    const data = { group_registry: { [this.data.get("field")]: value }};

    try {
      await axios.patch(url, data, {
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': Rails.csrfToken()
        }
      });
    } catch (error) {
      console.log(error);
    }
  }
}

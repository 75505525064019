import flatpickr from "flatpickr";

document.addEventListener("turbolinks:load", () => {
  const Russian = require("flatpickr/dist/l10n/ru.js").default.ru;

  flatpickr("[data-behavior='flatpickr-date-unlim']", {
    altInput: true,
    altFormat: 'F j, Y',
    dateFormat: 'Y-m-d',
    "locale": Russian,
    defaultDate: 'today'
  })
})